import React from 'react'
import DellEmcLogo from '../../../images/partners/dell-emo.svg';
import GoogleLogo from '../../../images/partners/google.svg';
import HpLogo from '../../../images/partners/hp.png';
import MicrosoftLogo from '../../../images/partners/microsoft.svg';
import CiscoLogo from '../../../images/partners/cisco.svg'
import huaweiLogo from '../../../images/partners/heuwei.svg'
import OracleLogo from '../../../images/partners/oracle.svg'
import FortniteLogo from '../../../images/partners/fortinet.svg'
import ServiceNowLogo from '../../../images/partners/service-now.png'

const Partners = () => {
    return (
        <div className="py-md-5">
            <h1 className="font-weight-bold text-center">Meet our OEM partners</h1>
            <p className="text-center mb-5 pb-5" style={{fontSize: '1.25rem'}}>We work with only the best, so that you can have the best</p>

            <div className="container">
                <div className="row">
                    <div className="col-md-3 mb-4 pb-3 text-center">
                        <img src={DellEmcLogo} alt="Dell Emc logo" className="img-fluid" style={{ maxWidth: '250px' }} />
                    </div>

                    <div className="col-md-3 mb-4 pb-3 text-center">
                        <img src={GoogleLogo} alt="google logo" className="img-fluid" style={{ maxWidth: '218px' }} />
                    </div>

                    <div className="col-md-3 mb-4 pb-3 text-center">
                        <img src={HpLogo} alt="HP logo" className="img-fluid" style={{ maxWidth: '90px' }} />
                    </div>

                    <div className="col-md-3 mb-4 pb-3 text-center">
                        <img src={MicrosoftLogo} alt="Microsoft logo" className="img-fluid" style={{ maxWidth: '250px' }} />
                    </div>

                    <div className="col-md-3 mb-3 pb-3 text-center">
                        <img src={CiscoLogo} alt="cisco logo" className="img-fluid" style={{ maxWidth: '180px' }} />
                    </div>

                    {/* <div className="col-md-3 mb-3 pb-3 text-center">
                        <img src={huaweiLogo} alt="Huawei logo" className="img-fluid" style={{ maxWidth: '100px' }} />
                    </div> */}

                    <div className="col-md-3 mb-3 text-center my-auto">
                        <img src={OracleLogo} alt="Oracle logo" className="img-fluid" style={{ maxWidth: '215px' }} />
                    </div>

                    <div className="col-md-3 mb-3 pt-md-0 pt-4 text-center my-auto">
                        <img src={FortniteLogo} alt="fortnite logo" className="img-fluid" style={{ maxWidth: '210px' }} />
                    </div>

                    <div className="col-md-3 mb-3 text-center my-auto">
                        <img src={ServiceNowLogo} alt="fortnite logo" className="img-fluid" style={{ maxWidth: '210px' }} />
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Partners
